/* General Stuff for Small Screen */
@media (max-width: 768px){
	h1{font-size: 25px;}
	h2{font-size: 22px;}
	h3 {font-size: 19px;}
	h4 {font-size:15px;}
	h5 {font-size: 13px;}
	p {font-size:11px;}
}
@media(max-width: 480px){
	h2{font-size: 18px;}
	h3{ font-size: 14px;}
	h4{font-size: 11px;}
	h5{font-size:10px;}
	p{font-size:9px;}
}
/* Override bootstrap stuff */
@media(max-width:767px){
	.navbar-home{
		background-color: rgba(0,0,0,0.7);
	}

	.navbar-home button{
		border-color: transparent !important;
	}
}

/*tooltip*/
.tooltip .tooltip-inner ul,.tooltip .tooltip-inner ul>li{
	list-style: none;
	padding: 5px 0;
	margin:0;
}
/*Mobile version front page*/
.mobile-frontpage{
	position: relative;
	padding-bottom: 125%;
}
.mobile-frontpage .mobile-home-picture{
	width: 100%;
	margin-top: 3em;
	margin-bottom: -11px;
	position: absolute;
	top:-41px;

}
/*@media(max-width: 767px){
	#mobile-version-logo{
		display: inline !important;
		width: 134px;
	    position: relative;
	    margin-top: 11px;
	    margin-left: 3%;
	}
}*/




/* fileinput */
.file-preview-other{
	padding-top:0;
}
.file-other-error{
	padding-top: 0;
}
.file-input .embed-responsive object{
    position: relative;
}
.page .file-preview{
	border-radius: 5px;
  	border: 3px dashed #595757;
  	padding: 5px;
  	margin: 13% 4% 13% 7%;
  	padding-bottom: 50%;
  	background-color: rgba(255,255,255,0.8);
  	width: auto;
}
.editor-fileinput .file-preview{
	padding-bottom: 40%;
}

.page .file-drop-zone{
	border-radius: 4px;
	text-align: center;
	vertical-align: middle;
	padding: 5px;
	margin:0;
}
.page .file-drop-zone-title{
	color: #fff;
	font-size: 3rem;
	padding: 5% 10px;
}

.page .highlighted {
    border:none !important;
}
.page .file-drop-zone-title .drop-zone-bg{
    background-image: url('../img/drop-01.png');
    background-size: 100%;
    padding-bottom: 42.9%;
    background-repeat: no-repeat;
}
.page .file-input .input-group{
    position:absolute;
    left:44%;
    width: 80%;
    top:110%;
}
.page .file-input .input-group-btn .btn{
    font-size: 19px;
}
.page .file-input .input-group-btn .btn.btn-primary{
    background-color: #F8B62C;
    border:none;
    color:#595757;
    border-radius: 5px;
    padding: 6px 20px;
    font-weight: bold;
    font-size: 20px;
}
.page .file-input .input-group-btn .btn.btn-primary:hover{

}
.page .file-input .form-control{
    height:40px;
    text-align: center;
    border-radius: 5px 0 0 5px;
    background-color: transparent;
    border:2px solid rgba(255,255,255,0.5);
    border-right:0px;
    font-size: 1.5em;
    color:#dcdcdd;
    overflow: hidden;
    font-family: Arial;

}
.page .kv-upload-progress{
    position: absolute;
    left: 44%;
    width: 80%;
    top: 105%;
}
 
.page .file-caption-name{
	max-height: none;
}
.page .file-preview-frame .file-caption-name{
    max-height: 20px;
}
@media(max-width: 768px){
    .page .form-control.file-caption.kv-fileinput-caption.kv-has-ellipsis{
        display: none;
    }
    .page .input-group{
        width: 40% !important;
        top: 0px !important;
        left: 0px !important;
        margin-top: 44%;
        margin-left: 20%;
    }

}
@media(max-width:480px){
    .page .input-group{
        margin-top: 19%;
        margin-left: 15s%;
    }
}
/* Shadow to add using Jquery*/
.shadow{
    -moz-box-shadow: 5px 3px 8px rgba(0,0,0,0.6);
    -webkit-box-shadow: 5px 3px 8px rgba(0,0,0,0.6);
    box-shadow: 5px 3px 8px rgba(0,0,0,0.6); /* For IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#000000')"; /* For IE 5.5 - 7 */
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength = 4, Direction = 135, Color = '#000000');
}
/*Word Price article on click*/

.article-active{
	background-color: #F8B62C !important;
	color: #595757 !important;
}

/* End of general small screen */

.page,.introduction,.procedure,.example,.editors,.wordPrice{
	overflow: hidden;
}
.carousel{
	padding-bottom: 50px;
}
.page header .logo{
	padding-left: 10%
}
.page header .logo-horizontal{
	background-image:url('../img/logo-h.png');
	background-position: center;
	background-repeat: no-repeat;
	padding-bottom:22%; 
}
.page header .logo img{
	width:100%;

}
.page header .slogan{
	color:#dcdcdd;
	padding-left: 10%;
	line-height: 150%;
	text-shadow:5px 5px 6px #000;
}
.page-loading{
	position: fixed;
	top:50px;
	left: 0;
	width: 100%;
	height:100%;
	background-color: #000;
	z-index: 9999;
}
.page-heading{
	text-align: center;
}
.page-heading .tag{
	padding:5px 0 15px;
	
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	
}

.page-heading h3{
	margin: 0;
	padding:0;
}

.style1{
	background-image:url('../img/background1.jpg');
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top:50px;
	padding-bottom: 11%;
}
@media(max-width: 768px){
	.style1{ padding-bottom: 20%;}
}

.switchheader .inner-item{
	width: 100%;
	position: relative;
	padding-bottom: 90%;
	height: 0;
}
.switchheader .item{
	display: none;
	left: 0;
	position: relative;
	-webkit-transition: .6s ease-in-out left;
	     -o-transition: .6s ease-in-out left;
	        transition: .6s ease-in-out left;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .switchheader .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
         -o-transition:      -o-transform .6s ease-in-out;
            transition:         transform .6s ease-in-out;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 1000;
            perspective: 1000;
  }
  .switchheader .item.switching.right,
  .switchheader .item.active.right {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }

  .switchheader .item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.switchheader .active,
.switchheader .switching{
  display: block;
}
.switchheader .active {
  left: 0;
}
.switchheader .switching{
  position: absolute;
  top: 0;
  width: 100%;
  
}
.switchheader .switching.right{
	left: 100%;
}
.switchheader .active.right {
  left: 100%;
}
#orderform .wrapper{
	margin-top: 5%;
	background-color: rgba(255,255,255,0.6);
	padding: 5% 10%;
	border-radius: 4%;
}
#orderform .wrapper header h4{
	text-align: center;
	margin:0;
	padding-bottom: 15px;
	border-bottom: 1px solid;
}
#orderform .wrapper .form-horizontal{
	padding-top: 20px;
}
#orderform .form-horizontal .form-group .control-label{
	text-align: left;
	margin-bottom: 5px;
}
#orderform .form-horizontal .form-group.prompt .control-label{
	padding-top: 0px;
}
#orderform .form-horizontal .form-group.prompt{
	padding-left: 15px;
  	padding-right: 15px;
}
#orderform .form-horizontal .form-group:last-child{
	margin-bottom: 0px;
	text-align: center;
}
#orderform .form-horizontal .form-group button.btn{
	padding: 6px 25%;
}
#orderform .form-horizontal .form-group button.btn.btn-primary{
	background-color: #F8B62C;
	border-color: #F8B62C;
	color: #595757;
	font-weight: bold;
}


@media (max-width: 768px){
	.page header .logo img{
		width: 100%;
	}
	.page header .slogan{
		font-size: 16px;
		text-align: left;
	}
}
@media (max-width: 480px){
	.page header .logo{
		margin-top: 11%;
	}
	.page header .slogan{
		font-size: 9px;
	}
}
/* Start of Service CSS */
.service .style-twig{
	padding: 0;
	position: relative;
	overflow: hidden;
}

.service article{
	padding-bottom: 3em;
	height: 610px;
}
.service article#basic-editing{
	background-color: #3E3A39;
}
.service article .small-title{
	margin-top: 2em;
	font-weight: bold;

}
.service article#basic-editing .yellow-text{
	color: #F8B62C;
	line-height: 1.5;
}

.service article .left-margin{
	margin-left: 3%;
}

.service article#basic-editing .title{
	font-size: 60px;
	font-weight: bold;
	margin-top: 13%;
}
.service article#basic-editing a{
	background-color: #F8B62C;
	line-height: 2;
	padding: 6px 5%;
	color: black;
	margin-top: 20em;
}
.service article a:hover{
	text-decoration: none;
}

.service .second-col{
	margin-left: 7%;
}

.service .white-text{
	color: white;
}

.service article form{
	text-align: right;
}

.service .priceToShowOnScreen{
	color:#F8B62C;
	margin: 2em 0;
}

.service .wordInput{
	width: 19%;
	border:0;
	border-bottom:solid 1px #fff; 
	outline:none;
	background-color: transparent;
	color: white;
	text-align: center;
}
.service .deep-wordInput{
	width: 19%;
	border:0;
	border-bottom:solid 1px #fff; 
	outline:none;
	background-color: transparent;
	text-align: center;
	color: #fff;

}
.deep-wordInput::-webkit-input-placeholder {
  color: #3E3A39;
}
.deep-wordInput:-moz-placeholder{
  color: #3E3A39;
}

.service label{
	display: inline-block;
	text-align: center;
	float: right;
	width: 35px;
	color: white;
	font-size: 18px;
}
.service #basic-editing .cross-line{
	position: absolute;
	top: 50%;
	width: 50%;
	right: -8%;
}

.service #deep-editing .cross-line{
	position: absolute;
	top: 50%;
	width: 50%;
	right: -38%;
}

.service article#basic-editing .text{
	margin-top: 7em;
}


/* Deep Editing CSS */
.service #deep-editing{
	position: absolute;
	background-color: #F8B62C;
	top: 0;
	width: 100%;
	left: 82%;
	overflow: hidden;
}
.service article#deep-editing .title{
	font-size: 60px;
	font-weight: bold;
	margin-top: 12%;
}
.service article#deep-editing .text{
	margin-top: 10%;
}
.service .black-text{
	color: #3E3A39;
	line-height: 1.5;
}
.service article#deep-editing a{
	background-color: #3E3A39;
	line-height: 2;
	padding: 7px 5%;
	color: #F8B62C;
	margin-top: 9em;
	margin-bottom: 3%;
	opacity: 0;

	-webkit -webkit-transition: .5s ease-in-out opacity;
	-o-transition: .5s ease-in-out opacity;
	transition: .5s ease-in-out opacity;
}
.service article#deep-editing:after{
	content:'';
	display: block;
	position: absolute;
	top: 0;
	width: 0; 
	height: 0; 
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
	border-left: 22px solid #3E3A39;
}

@media(min-width: 1300px){
	.service article{
		height: 620px;
	}
}
@media(max-width: 960px){
	.service article title{
		font-size: 50px;
	}
	.service .cross-line{
		width: 70%;
	}
	.service article#basic-editing a{
		margin-top: 16em;
	}
	.service article#deep-editing a{
		margin-top: 4em;
	}
	.service article#basic-editing .text{
		margin-top: 6em;
	}
}


@media(max-width: 768px){
	.service article{
		height: initial;
	}
	.service article#deep-editing .title{
		font-size: 40px;
	}
	.service article#basic-editing .title{
		font-size: 40px;
	}
	.service article#deep-editing{
		position: relative;
		left: 0;
	}
	.service article#deep-editing a{
		opacity: 1;
	}
	.service article#basic-editing a{
		margin-top: 10em;
	}
	.service article#deep-editing a{
		margin-top: 3em;
	}
	.service .black-text{
		line-height: 1.1;
	}
	.service #deep-editing .cross-line{
		width: 54%;
		right: -48%;
	}
	.service #basic-editing .cross-line{
		width: 63%;
		right: -6%;
	}

	.service article#deep-editing:after{
		display:none;
	}
}
@media(max-width: 480px){
	.service article#deep-editing .title{
		font-size: 30px;
	}
	.service article#basic-editing .title{
		font-size: 30px;
	}
	.service article#basic-editing a{
		margin-top: 9em;
	}
	.service article a{
		font-size: 10px;
	}

	.service article#deep-editing .text{
		margin-top: 7em;
	}
}

/*Start of Intro CSS */

.introduction img{
	margin: auto;
	display: block;
	width: 50%
}

.introduction .row p{
	text-align: center;
	margin-top: 10%;
	
}
.introduction .bottom-text{
	padding-top: 5%;
	width: 76%;
	margin: auto;
	color: #717071;
	font-size: 17px;

}
.introduction .page-heading .tag{
	background: #3E3A39;
	color: #fff;
}
/*.sm-margin{
	margin-top: 9%;
	margin-bottom: 9%;
}*/
.introduction .page-body{
	margin: 9% auto;
}
@media (max-width: 960px){
	.introduction .margin-on-top{
		margin-top: 7%;
	}
}
@media (max-width:768px){
	.introduction .bottom-text{
		font-size: 12px;
	}
	.introduction img{
		width: 72%;
	}

	.introduction .page-heading .tag{
	background: #F8B62C;
	color: #3E3A39;

	}


}


/*End of Intro CSS */

/*Start of Procedure CSS */

.style2{
	background-color:#3E3A39;
}
.procedure .page-heading .tag{
	color: #000;
	background-color:#fff;
}
.procedure .page-body{

}
.procedure .carousel{
  color:#fefefe;
  font-size: 18px;
}
.procedure .carousel-inner{
	min-height: 20em;
	
}

.procedure .carousel-inner .item figcaption{
  width: 45%;
  margin: auto;
  font-size: 17px;
}
@media (max-width:768px){
	.carousel-inner{
		min-height: 12em;
	}
}
.procedure .carousel-inner .item figure{
  width: 17%;
  margin: 3em auto;
}
.procedure .carousel-captionlist{
  text-align: center;
  font-size: 18px;
  margin: 2em auto;
}
.procedure .carousel-captionlist li{
  color:#fefefe;
  display: inline-block;
  padding:0.5% 2.5%;
  margin-right: 3em;
  cursor: pointer;
  border:1px solid transparent;
}
.procedure .carousel-captionlist li.active{
	border:1px solid #fefefe;
	border-radius: 10px;

}
.procedure .carousel-control.left,.procedure .carousel-control.right{
	background: none;
}

@media (min-width: 768px ){
	.procedure #small-version{ display: none;}
}
@media(max-width:767px){
	.procedure #big-version{display:none;}
	.procedure img{
		width: 50%;
	}
	
	.procedure [class*="col-"]{
		padding-left: 2px;
		padding-right: 2px;
		margin-top: 4px;
	}
	.procedure #slide3{
		padding-bottom: 6px;
	}
	.procedure article{
		background-color: #3E3A39;
		padding: 2em 0;
		position: relative;
		height: 229px;
		border:none;
	}
	.procedure article:hover{
		cursor: pointer;
	}

	.procedure article h2{
		color: white;
	}
	.procedure article h3{
		color: white;
		line-height: 1.4;
		padding: 0 4px;
	}
	.procedure article h4{
		color: white;
	}
	.procedure .back{
		position: absolute;
		top: 5%;
	}
	.procedure #reply-fig{
		height: 370px;
	}
	.procedure #reply-text{
		margin-top: 15%;
		padding: 0 2em;
	}
	.procedure .page-heading .tag{
		background-color: #3E3A39;
		color: white;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

}
@media( max-width: 480px){
	.procedure img{
		margin-top: 18%;
	}
	.procedure #reply-text{
		line-height: 1.5;
		padding: 0 1.5em;
	}
	.procedure article h3{
		padding: 0 5px;
	}
	.procedure #reply-fig img{
		margin-top: 6%;
	}
	.procedure #reply-fig{
		height: 320px;
	}
}
/*End of Procedure CSS */

/* Example CSS */

/*.example .page-heading .tag{*/
	/*background-color: #3E3A39;*/
	/*color: white;*/
/*}*/
/*.example #example-heading{*/
	/*margin-bottom: 5%;*/
/*}*/
/*.example .carousel-inner .item figcaption{*/
	/*font-size: 18px;*/
	/*margin: 10px;*/
	/*text-align: center;*/
/*}*/
/*.example figure img{*/
	/*margin-bottom: 5%;*/
	/*margin-top: 3%;*/
/*}*/

/*.example .carousel.cover{*/
	/*padding-bottom: 56%*/
/*}*/
/*.carousel.cover .carousel-inner{*/
	/*position: absolute;*/
	/*width: 1000%;*/
	/*left:50%;*/
	/*margin-left: -30%;*/
	/*-webkit-transition: .6s ease-in-out margin-left;*/
	/*-o-transition: .6s ease-in-out margin-left;*/
	  /*transition: .6s ease-in-out margin-left;*/
/*}*/
/*.carousel.cover .carousel-inner .item{*/
	/*display: block;*/
	/*float: left;*/
	/*width: 6%;*/
	
/*}*/
/*.carousel.cover .carousel-inner .item figure{*/
	/*width: 60%;*/
	/*margin: 5% auto;*/
	/*-webkit-filter:blur(2px);*/
	/*filter:blur(2px);*/
	/*-webkit-transition: .6s ease-in-out all;*/
	/*-o-transition: .6s ease-in-out all;*/
	  /*transition: .6s ease-in-out all;*/
	 /*cursor: pointer;*/
/*}*/
/*.carousel.cover .carousel-inner .item.active figure{*/
	/*width: 100%;*/
	/*margin: 0 auto;*/
	/*-webkit-filter:none;*/
	/*filter:none;*/

/*}*/
/*.example .sat-example{*/
	/*padding-top: 2em;*/
/*}*/
/*.example .sat-example article{*/
	/*border-style: solid;*/
	/*border-width: thin;*/
	/*border-color: #F8B62C;*/
	/*border-radius: 5px;*/
	/*width: 100%;*/
	/*margin:auto;*/
	/*background-color: #F8B62C;*/
	/*margin-bottom: 3em;*/
	/*padding: 1em 0;*/
/*}*/
/*.example .sat-example h4{*/
	/*width: 65%;*/
	/*margin: auto;*/
	/*line-height: 1.4;*/
/*}*/
/*@media (max-width:768px){*/
	/*.example{*/
		/*margin-bottom: 2em;*/
	/*}*/
	/*.example #small-body img{*/
		/*margin-bottom: 3%;*/
		/*margin-top: 7%;*/
		/*width: 80%;*/
		/*margin-left: auto;*/
		/*margin-right: auto;*/
	/*}*/
/*}*/




/* XiuXiu effects */
.xiuxiu-fade{
	opacity: 0;
	-webkit-transition: .3s ease-in-out opacity;
	     -o-transition: .3s ease-in-out opacity;
	        transition: .3s ease-in-out opacity;
}
