/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2015
 * @package bootstrap-fileinput
 * @version 4.2.3
 *
 * File input styling for Bootstrap 3.0
 * Built for Yii Framework 2.0
 * Author: Kartik Visweswaran
 * Year: 2015
 * For more Yii related demos visit http://demos.krajee.com
 */
.file-input {
    overflow-x: auto;
}

.file-loading {
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    font-size: 999px;
    text-align: right;
    color: #fff;
    background: transparent url('../img/loading.gif') top left no-repeat;
    border: none;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    background: none repeat scroll 0 0 transparent;
    cursor: inherit;
    display: block;
}

.file-caption .glyphicon {
    display: inline-block;
    min-width: 18px;
    margin-top: 2px;
}

.file-caption-name {
    display: inline-block;
    overflow: hidden;
    max-height: 20px;
    padding-right: 10px;
    word-break: break-all;
}

.file-caption-ellipsis {
    position: absolute;
    right: 10px;
    margin-top: -6px;
    font-size: 1.2em;
    display: none;
    font-weight: bold;
    cursor: default;
}

.kv-has-ellipsis .file-caption-ellipsis {
    display: inline;
}

.kv-has-ellipsis {
    padding-right: 17px;
}

.kv-search-container .kv-search-clear {
    position: absolute;
    padding: 10px;
    right: 0px;
}

.file-error-message {
    background-color: #f2dede;
    color: #a94442;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
}

.file-error-message pre, .file-error-message ul {
    margin: 5px 0;
    text-align: left;
}

.file-caption-disabled {
    background-color: #EEEEEE;
    cursor: not-allowed;
    opacity: 1;
}

.file-input .btn[disabled], .file-input .btn .disabled {
    cursor: not-allowed;
}

.file-preview {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    width: 100%;
    margin-bottom: 5px;
}

.file-preview-frame {
    display: table;
    margin: 8px;
    height: 160px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 5px 0px #a2958a;
    padding: 6px;
    float: left;
    text-align: center;
    vertical-align: middle;
}

.file-preview-frame:hover {
    box-shadow: 3px 3px 5px 0px #333;
}

.file-preview-image {
    height: 160px;
    vertical-align: text-center;
}

.file-preview-text {
    width: 160px;
    color: #428bca;
    font-size: 11px;
    text-align: center;
}

.file-preview-other {
    padding-top: 48px;
    text-align: center;
}

.file-preview-other i {
    font-size: 2.4em;
}

.file-other-error {
    width: 100%;
    padding-top: 30px;
    text-align: right
}

.file-input-new .file-preview, .file-input-new .close, .file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button, .file-input-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove-button, .file-input-ajax-new .fileinput-upload-button {
    display: none;
}

.loading {
    background: transparent url('../img/loading.gif') no-repeat scroll center center content-box !important;
}

.wrap-indicator {
    font-weight: bold;
    color: #245269;
    cursor: pointer;
}

.file-actions {
    text-align: left;
}

.file-footer-buttons {
    float: right;
}

.file-thumbnail-footer .file-caption-name {
    padding-top: 4px;
    font-size: 11px;
    color: #777;
}

.file-upload-indicator {
    padding-top: 2px;
    cursor: default;
}

.file-upload-indicator:hover {
    font-size: 1.2em;
    font-weight: bold;
    padding-top: 0;
}

.file-drop-zone {
    border: 1px dashed #aaa;
    border-radius: 4px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 12px 15px 12px 12px;
    padding: 5px;
}

.file-drop-zone-title {
    color: #aaa;
    font-size: 40px;
    padding: 85px 10px;
}

.highlighted {
    border: 2px dashed #999 !important;
    background-color: #f0f0f0;
}

.file-uploading {
    background-image: url('../img/loading-sm.gif');
    background-position: center bottom 10px;
    background-repeat: no-repeat;
    opacity: 0.6;
}

.file-icon-large {
    font-size: 1.2em;
}

/**
 * Customize
*/
