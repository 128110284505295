@media (max-width: 768px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 19px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 13px; }
  p {
    font-size: 11px; } }

@media (max-width: 480px) {
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 14px; }
  h4 {
    font-size: 11px; }
  h5 {
    font-size: 10px; }
  p {
    font-size: 9px; } }

.info-article {
  border-style: solid;
  border-width: 15px;
  border-color: #F7F7F7;
  padding: 3em;
  background-color: #EEEFEF;
  margin-bottom: 5em; }

.editor-order-article {
  padding: 1em; }

.survey-article {
  border-style: solid;
  border-color: #7A7A7B;
  border-width: 1px;
  background-color: white; }

.navigate-bar {
  list-style-type: none;
  width: 60%;
  padding: 5%;
  margin: auto; }
  .navigate-bar a, .navigate-bar a:visited, .navigate-bar a:hover {
    color: inherit;
    text-decoration: none; }

.color-light {
  background-color: #F5BA40 !important;
  color: #F7F7F7; }

.nav-button-article {
  text-align: center;
  background-color: #F8B62C;
  padding: 1em;
  margin-bottom: 2px;
  border-radius: 5px;
  padding-bottom: .6em; }

a, a:visited, a:hover {
  color: inherit;
  text-decoration: none; }

.submit-button {
  background-color: #F8B62C;
  color: #F7F7F7;
  width: 100%;
  margin-top: 4em; }

.grey-background {
  background-color: #EEEFEF; }

.utility-red {
  color: #EA6344; }

.utility-yellow {
  color: #F8B62C; }

.utility-xs-offset {
  margin-left: 11.5%; }

.utility-grey {
  color: #7A7A7B; }

.utility-orange {
  color: #EA5414; }

.utility-background-orange {
  background-color: #EA5414; }

.utility-background-grey {
  background-color: #7A7A7B; }

.utility-white {
  color: white; }

.editors {
  padding-bottom: 5em;
  margin-top: 5em;
  background-color: #3E3A39; }
  .editors .page-heading .tag {
    background-color: white; }
  .editors .header-text {
    line-height: 1.7;
    width: 90%;
    margin: auto;
    margin-top: 3em;
    color: white;
    text-align: center; }
  .editors .content {
    margin-top: 2em; }
    @media (max-width: 980px) {
      .editors .content {
        margin-top: 2.8em; } }
    .editors .content img {
      width: 60%;
      margin: auto; }
      @media (min-width: 480px) and (max-width: 980px) {
        .editors .content img {
          width: 50%; } }
    .editors .content .info {
      color: #DCDCDD; }
      .editors .content .info .name {
        margin-top: 1.5em;
        color: white; }
      .editors .content .info .school {
        margin-top: 2em; }
      @media (max-width: 980px) {
        .editors .content .info .major {
          margin-bottom: 3em; } }
      .editors .content .info .description {
        width: 90%;
        margin: auto;
        margin-top: 2.7em;
        line-height: 1.3; }
  .editors .join-us-link {
    color: white;
    text-align: right;
    margin-top: 3em; }
    .editors .join-us-link:hover {
      color: #DCDCDD; }

@media (max-width: 768px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 19px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 13px; }
  p {
    font-size: 11px; } }

@media (max-width: 480px) {
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 14px; }
  h4 {
    font-size: 11px; }
  h5 {
    font-size: 10px; }
  p {
    font-size: 9px; } }

.info-article {
  border-style: solid;
  border-width: 15px;
  border-color: #F7F7F7;
  padding: 3em;
  background-color: #EEEFEF;
  margin-bottom: 5em; }

.editor-order-article {
  padding: 1em; }

.survey-article {
  border-style: solid;
  border-color: #7A7A7B;
  border-width: 1px;
  background-color: white; }

.navigate-bar {
  list-style-type: none;
  width: 60%;
  padding: 5%;
  margin: auto; }
  .navigate-bar a, .navigate-bar a:visited, .navigate-bar a:hover {
    color: inherit;
    text-decoration: none; }

.color-light {
  background-color: #F5BA40 !important;
  color: #F7F7F7; }

.nav-button-article {
  text-align: center;
  background-color: #F8B62C;
  padding: 1em;
  margin-bottom: 2px;
  border-radius: 5px;
  padding-bottom: .6em; }

a, a:visited, a:hover {
  color: inherit;
  text-decoration: none; }

.submit-button {
  background-color: #F8B62C;
  color: #F7F7F7;
  width: 100%;
  margin-top: 4em; }

.grey-background {
  background-color: #EEEFEF; }

.utility-red {
  color: #EA6344; }

.utility-yellow {
  color: #F8B62C; }

.utility-xs-offset {
  margin-left: 11.5%; }

.utility-grey {
  color: #7A7A7B; }

.utility-orange {
  color: #EA5414; }

.utility-background-orange {
  background-color: #EA5414; }

.utility-background-grey {
  background-color: #7A7A7B; }

.utility-white {
  color: white; }

.faq {
  background-color: black;
  padding: 3em 0; }
  .faq a, .faq a:hover, .faq a:visited, .faq a:active {
    text-decoration: none;
    color: inherit; }
  .faq .faq-link {
    background-color: transparent;
    border: solid 1px;
    border-color: #F8B62C;
    border-radius: 5px;
    color: #F8B62C;
    margin-top: 2em; }
    @media (min-width: 780px) {
      .faq .faq-link {
        width: 10.77777777%;
        margin-left: 44%;
        padding: 0 1em; } }
    .faq .faq-link:hover {
      background-color: #F8B62C;
      color: black; }
  .faq hr {
    border: 0;
    border-top: 1px solid #F8B62C;
    width: 80%;
    margin: auto;
    margin-top: 8em; }
    @media (max-width: 980px) {
      .faq hr {
        margin-top: 7.5em; } }
  .faq .footer img {
    width: 95%;
    margin: auto; }
  .faq .footer img#qrcode {
    margin-top: 2em; }
  .faq .footer .info {
    color: #DCDCDD; }
    @media (max-width: 991px) and (min-width: 768px) {
      .faq .footer .info {
        text-align: center; } }
  .faq .footer .top-info {
    margin-top: 24%; }
    @media (max-width: 991px) and (min-width: 768px) {
      .faq .footer .top-info {
        text-align: center; } }
  .faq .footer .title {
    color: white;
    font-weight: bold;
    margin-bottom: 61%; }
  .faq .footer .options {
    color: #DCDCDD; }
    .faq .footer .options:hover {
      color: white; }
  .faq .copyright {
    text-align: right;
    color: #DCDCDD;
    font-size: 10px; }
  .faq .copyright-top {
    margin-top: 5em; }

@media (max-width: 768px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 19px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 13px; }
  p {
    font-size: 11px; } }

@media (max-width: 480px) {
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 14px; }
  h4 {
    font-size: 11px; }
  h5 {
    font-size: 10px; }
  p {
    font-size: 9px; } }

.info-article {
  border-style: solid;
  border-width: 15px;
  border-color: #F7F7F7;
  padding: 3em;
  background-color: #EEEFEF;
  margin-bottom: 5em; }

.editor-order-article {
  padding: 1em; }

.survey-article {
  border-style: solid;
  border-color: #7A7A7B;
  border-width: 1px;
  background-color: white; }

.navigate-bar {
  list-style-type: none;
  width: 60%;
  padding: 5%;
  margin: auto; }
  .navigate-bar a, .navigate-bar a:visited, .navigate-bar a:hover {
    color: inherit;
    text-decoration: none; }

.color-light {
  background-color: #F5BA40 !important;
  color: #F7F7F7; }

.nav-button-article {
  text-align: center;
  background-color: #F8B62C;
  padding: 1em;
  margin-bottom: 2px;
  border-radius: 5px;
  padding-bottom: .6em; }

a, a:visited, a:hover {
  color: inherit;
  text-decoration: none; }

.submit-button {
  background-color: #F8B62C;
  color: #F7F7F7;
  width: 100%;
  margin-top: 4em; }

.grey-background {
  background-color: #EEEFEF; }

.utility-red {
  color: #EA6344; }

.utility-yellow {
  color: #F8B62C; }

.utility-xs-offset {
  margin-left: 11.5%; }

.utility-grey {
  color: #7A7A7B; }

.utility-orange {
  color: #EA5414; }

.utility-background-orange {
  background-color: #EA5414; }

.utility-background-grey {
  background-color: #7A7A7B; }

.utility-white {
  color: white; }

.asterik {
  color: red; }

button.confirm-btn {
  background-color: #EA6344;
  color: white;
  border: none; }
  button.confirm-btn:hover {
    background-color: #EA3D15;
    color: white; }

button.return-btn {
  background-color: #3E3A39;
  color: #F8B62C; }
  button.return-btn:hover {
    background-color: #252222;
    color: #F8B62C; }

button.cancel-btn {
  background-color: #DCDCDC; }
  button.cancel-btn:hover {
    background-color: #C1C1C1; }

#chooseType .modal-dialog {
  display: none;
  transition: .3s ease-in-out all; }

#chooseType .modal-dialog.fading.up {
  transform: translate(0, -100%); }

#chooseType .modal-dialog.active {
  display: block;
  transform: translate(0, 0); }

#phase2modal .modal-form {
  background-color: #DCDDDD;
  border-radius: 0;
  border: none; }

#phase2modal .btn-row {
  margin-top: 1em; }

#phase3modal img.shendu-pic {
  width: 45%;
  margin: auto; }

#phase3modal .shendu-caption {
  font-size: 20px;
  color: #EA6344;
  margin-top: 1em;
  font-weight: 600; }

#phase3modal .rewrite-wrapper:hover {
  cursor: pointer; }

#phase3modal .circle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #7A7A7B;
  position: absolute;
  top: 4px; }

#phase3modal input.rewrite-radio-btn {
  visibility: hidden; }

#phase3modal .text {
  color: #7A7A7B;
  margin-left: 10px;
  font-size: 16px; }

#phase3modal .details {
  margin-left: 27px;
  margin-top: 1em;
  font-size: 11px;
  display: none; }

#phase3modal .selected > .circle {
  background-color: #EA6344; }

#phase3modal .selected > .text {
  color: black;
  font-weight: bolder; }

#phase3modal .selected > .details {
  display: block; }

#phase3modal .rewrite-wrapper {
  transition: all 20s ease; }

#phase3modal .btn-row {
  margin-top: 2em; }

@media (max-width: 768px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 19px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 13px; }
  p {
    font-size: 11px; } }

@media (max-width: 480px) {
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 14px; }
  h4 {
    font-size: 11px; }
  h5 {
    font-size: 10px; }
  p {
    font-size: 9px; } }

.info-article {
  border-style: solid;
  border-width: 15px;
  border-color: #F7F7F7;
  padding: 3em;
  background-color: #EEEFEF;
  margin-bottom: 5em; }

.editor-order-article {
  padding: 1em; }

.survey-article {
  border-style: solid;
  border-color: #7A7A7B;
  border-width: 1px;
  background-color: white; }

.navigate-bar {
  list-style-type: none;
  width: 60%;
  padding: 5%;
  margin: auto; }
  .navigate-bar a, .navigate-bar a:visited, .navigate-bar a:hover {
    color: inherit;
    text-decoration: none; }

.color-light {
  background-color: #F5BA40 !important;
  color: #F7F7F7; }

.nav-button-article {
  text-align: center;
  background-color: #F8B62C;
  padding: 1em;
  margin-bottom: 2px;
  border-radius: 5px;
  padding-bottom: .6em; }

a, a:visited, a:hover {
  color: inherit;
  text-decoration: none; }

.submit-button {
  background-color: #F8B62C;
  color: #F7F7F7;
  width: 100%;
  margin-top: 4em; }

.grey-background {
  background-color: #EEEFEF; }

.utility-red {
  color: #EA6344; }

.utility-yellow {
  color: #F8B62C; }

.utility-xs-offset {
  margin-left: 11.5%; }

.utility-grey {
  color: #7A7A7B; }

.utility-orange {
  color: #EA5414; }

.utility-background-orange {
  background-color: #EA5414; }

.utility-background-grey {
  background-color: #7A7A7B; }

.utility-white {
  color: white; }

.example .page-heading .tag {
  background-color: #3E3A39;
  color: white; }

.example #example-heading {
  margin-bottom: 5%; }

.example .big-screen {
  background-image: url("../img/examples.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-bottom: 4em; }
  @media (max-width: 480px) {
    .example .big-screen {
      min-height: 0; } }
  .example .big-screen figure.left-column {
    margin-left: 3em;
    width: 100%; }
    @media (max-width: 991px) {
      .example .big-screen figure.left-column {
        margin-left: 2em; } }
  .example .big-screen figure.right-column {
    width: 100%;
    margin-left: 1em; }
  .example .big-screen figcaption {
    font-weight: bold; }
  .example .big-screen img.download-figure-left-column {
    margin-left: 8%; }
  .example .big-screen img.download-figure-right-column {
    margin-left: 8%; }
  .example .big-screen .first-row {
    margin-top: 8.5em; }
    @media (max-width: 991px) {
      .example .big-screen .first-row {
        margin-top: 9em; } }
  .example .big-screen .second-row {
    margin-top: 10em; }
    @media (max-width: 991px) {
      .example .big-screen .second-row {
        margin-top: 7em; } }

.example .small-screen {
  background-image: url("../img/cross.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-bottom: 8em; }
  .example .small-screen .first-row {
    margin-top: 7em; }
  .example .small-screen .second-row {
    margin-top: 9em; }

/*# sourceMappingURL=homeUsingSASS.css.map */
